window.app = window.app || {};
window.app.popupHandler = (function() {

	var inited = false,
		$popup;

	function init() {
		if (inited) {return false;}

		$popup = $('#popup');

		$('body').on('click', '[data-popup-trigger]', function(e) {
			e.preventDefault();
			e.stopPropagation();

			var $this = $(this),
				$content = $('[data-popup-content="' + $this.attr('data-popup-trigger') + '"]'),
				id,
				cssClass = ($this.is('[data-popup-class]')) ? 'popup-' + $this.attr('data-popup-class').split(' ').join(' popup-') : '';

			if ($content.length === 0) {
				return false;
			}

			id = $content.attr('data-popup-id');

			if (!id) {
				id = new Date().getTime();
				$content.attr('data-popup-id', id);
				$content.after('<div data-popup-placeholder="' + id + '"></div>');
			}

			open('[data-popup-content][data-popup-id="' + id + '"]', cssClass);
		});

		$('body').on('click', '#popup [data-popup-close]', function(e) {
			e.preventDefault();
			e.stopPropagation();
			close();
		});

		$('[data-popup-open]:eq(0)').each(function() {
			open(this, '');
		});

		inited = true;
	}

	function open(content, cssClass) {
		var $content = $(content);

		cssClass += ($content.is('[data-popup-class]')) ? ' popup-' + $content.attr('data-popup-class').split(' ').join(' popup-') : '';
		$popup.cssClass = cssClass;
		$popup.addClass(cssClass);
		$popup.find('.popup-main').empty().removeAttr('style').append($content);
		$popup.addClass('popup-opened');
		
		$('body').addClass('noscroll');
	}

	function close() {
		var $content = $popup.find('[data-popup-content]'),
			$popupMain = $popup.find('.popup-main');

		$content.find('.video iframe').attr('src', '');

		$popupMain.css('height', $popupMain.innerHeight());
		if ($content.attr('data-popup-id')) {
			$('[data-popup-placeholder="' + $content.attr('data-popup-id') + '"]').before($content);
		}

		$popup.removeClass('popup-opened');

		setTimeout(function() {
			$popup.removeClass($popup.cssClass);
		}, 1000);

		$('body').removeClass('noscroll');
		$('input[type="file"]').val('');
		
		if ($popup.is('.popup-success') || $popup.is('.popup-registration')) {
			location.reload();
		}
	}

	return {
		init: init,
		open: open,
		close: close
	};

})();
